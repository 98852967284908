import { render, staticRenderFns } from "./BaseConfigCuentas.vue?vue&type=template&id=006dc510&scoped=true&"
import script from "./BaseConfigCuentas.vue?vue&type=script&lang=js&"
export * from "./BaseConfigCuentas.vue?vue&type=script&lang=js&"
import style0 from "./BaseConfigCuentas.vue?vue&type=style&index=0&id=006dc510&lang=scss&scoped=true&"
import style1 from "./BaseConfigCuentas.vue?vue&type=style&index=1&id=006dc510&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "006dc510",
  null
  
)

export default component.exports