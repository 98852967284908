<template>
  <div class="md-layout md-gutter">
    <div class="md-layout-item md-size-100 md-small-size-100">
    <md-table >
      <md-table-toolbar>
        <h1 class="md-title">Configurar Cuentas</h1>
        <p class="md-title" title="Total configuraciones agregdas">
        {{list_data.mdData.length}}/{{choosen_type_account.length}}</p>
        <md-menu md-size="big" md-direction="bottom-end">
          <md-button class="md-icon-button" @click="go_to('/')">
            <md-icon>keyboard_backspace</md-icon>
          </md-button>
          <md-menu md-size="auto">
            <md-button @click.prevent="showDialog=true">
              <md-icon>filter_list</md-icon>
            </md-button>
          </md-menu>
          <md-menu md-size="auto">
            <md-button class="md-icon-button" @click.prevent="list_resumen()">
              <md-icon>view_stream</md-icon>
            </md-button>
          </md-menu>
        </md-menu>
      </md-table-toolbar>
      <md-table-row>
        <md-table-head class="md-xsmall-hide md-small-hide">#</md-table-head>
        <md-table-head>Descripción</md-table-head>
        <md-table-head >Cuenta</md-table-head>
        <md-table-head >Nomenclatura</md-table-head>
        <md-table-head class="md-xsmall-hide md-small-hide">Naturaleza</md-table-head>
        <md-table-head >Editar</md-table-head>
        <md-table-head >Anular</md-table-head>
      </md-table-row>
      <md-table-row v-for="(item, index) in list_data.mdData" :key="item.id"
      :class="{ 'null error-text': !item.status}">
        <md-table-cell class="md-xsmall-hide md-small-hide">{{index +  1}}</md-table-cell>
        <md-table-cell >
          <span v-if="!item.edit">{{item.description }}</span>
          <md-field v-if="item.edit">
            <md-input
            v-model="item.description" :disabled="sending" />
          </md-field>
        </md-table-cell>
        <md-table-cell v-if="item.edit">
          <md-autocomplete autocomplete="off"
            id="account_name" class="autocomplete"
            :disabled="sending"
            v-model="item.account_name"
            :title="!item.account_bank_name ? item.account_name :
            item.account_name + ' ('+ item.account_bank_name+' )'"
            md-input-name="account"
            :md-options="list_accounts"
            @md-selected="set_account"
            @md-changed="get_list_accounts" >
          <template
            slot="md-autocomplete-item" slot-scope="{ item, term }">
            <span v-if="item.account_bank" :title="item.name +' ('+ item.name_account_bank+' )'">
              {{ item.nature }} {{ item.name }} ( {{item.name_account_bank}} )</span>
            <md-highlight-text :md-term="term" v-if="!item.account_bank">
              {{ item.nature }} {{ item.name }}</md-highlight-text>
          </template>
          </md-autocomplete>
        </md-table-cell>
        <md-table-cell v-else >
        <span>{{item.account_name }}</span>
        <span v-if="item.account_bank_name"> ( {{item.account_bank_name }} )</span>
        </md-table-cell>
        <md-table-cell  >
        {{ item.nomenclature }}</md-table-cell>
        <md-table-cell class="md-xsmall-hide md-small-hide">
          {{ item.nature }}
        </md-table-cell>
        <md-table-cell>
          <md-button v-if="!item.edit" :disabled="!item.status" class="md-icon-button"
            @click="change_to_edit_detail(item, index)">
            <md-icon class="md-size-1x" >edit</md-icon>
          </md-button>
          <md-button v-if="item.edit && !sending" class="md-icon-button"
            @click="confirmRow(item, index)">
            <md-icon class="md-size-1x">check</md-icon>
          </md-button>
          <md-button v-if="item.edit && sending" class="md-icon-button">
            <md-progress-spinner class="md-primary"
            :md-diameter="30" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
          </md-button>
          <md-button v-if="item.edit" class="md-icon-button"
              @click="cancel(item)">
              <md-icon class="md-size-1x">close</md-icon>
          </md-button>
        </md-table-cell>
        <md-table-cell>
          <md-button :disabled="!item.status"
          title="Anular registro"
          class="md-icon-button" @click="delete_document(item, index)">
              <md-icon class="md-size-1x">remove</md-icon>
          </md-button>
        </md-table-cell>
      </md-table-row>
    </md-table>
    <md-progress-bar md-mode="indeterminate" v-if="sending_request" />
    <md-snackbar
      :md-active.sync="confSaved">
    La configuracion para {{ message }} ha sido actualizada!</md-snackbar>
    </div>
    <md-dialog :md-active.sync="showDialog">
      <md-dialog-title>Filtros configuración</md-dialog-title>
      <md-card-content>
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-small-size-100">
            <div class="md-layout-item md-small-size-100">
              <md-field >
                <label for="description">Nombre</label>
                <md-input @keyup.enter="list()"
                v-model="filters.description" type="text" :disabled="sending_request" />
              </md-field>
            </div>
            <md-field class="md-focused">
              <label for="limit">Límite</label>
              <md-input @keyup.enter="list()"
              v-model="filters.limit" type="number" :disabled="sending_request" />
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100">
            <md-checkbox v-model="filters.status">ACTIVA</md-checkbox>
          <span v-if="filters.status" style="color: #ff5252;
          font-weight: 500;" for="status">SÍ</span>
          <span v-if="!filters.status" style="color: #ff5252;
          font-weight: 500;" for="status">NO</span>
          </div>
        </div>
      </md-card-content>
      <md-dialog-actions>
        <md-button class="md-primary" @click="showDialog = false">Cerrar</md-button>
        <md-button class="md-primary" @click="reset_filters">Limpiar filtro</md-button>
        <md-button class="md-primary" @click="list()">Filtrar</md-button>
      </md-dialog-actions>
      <br>
      <br>
      <br>
    </md-dialog>
    <md-dialog :md-active.sync="showDialogListConfig">
      <md-dialog-title>Configuraciones de clientes</md-dialog-title>
      <md-card-content>
        <p>Duplicar configuración para base</p>
        <div class="md-layout md-gutter">
          <md-table >
            <md-table-row>
              <md-table-head class="md-xsmall-hide md-small-hide">#</md-table-head>
              <md-table-head>Cliente</md-table-head>
              <md-table-head >Total cuentas</md-table-head>
              <md-table-head >Duplicar</md-table-head>
            </md-table-row>
            <md-table-row v-for="(item, index) in list_data_bases" :key="item.id">
              <md-table-cell class="md-xsmall-hide md-small-hide">{{index +  1}}</md-table-cell>
              <md-table-cell >
                {{item.company_name }}
              </md-table-cell>
              <md-table-cell >
                {{item.total_account}}
              </md-table-cell>
              <md-table-cell>
                <md-button  class="md-icon-button"
                    @click="retrieveDuplicate(item.company)">
                    <md-icon class="md-size-1x">content_copy</md-icon>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </div>
      </md-card-content>
      <md-dialog-actions>
        <md-button class="md-primary" @click="showDialogListConfig = false">Cerrar</md-button>
        <md-button class="md-primary" @click="reset_filters">Limpiar filtro</md-button>
      </md-dialog-actions>
      <br>
      <br>
      <br>
    </md-dialog>
  </div>
</template>
<script>
/* eslint-disable */
import Swal from 'sweetalert2';
export default {
  name: 'FormConfigCuentas',
  created(){
    this.office = this.get_data_raw('office')    
    this.list(1, this.rowsPerPage);
  },
  data() {
    return {
      company: null,
      office: null,
      message: null,
      list_accounts: [],
      base: process.env.VUE_APP_BASE_URL,
      list_data: {
        mdCount: null,
        mdPage: null,
        mdData: []
      },
      list_data_bases: [],
      choosen_type_account: [
        {value:'IMPUESTO DISTRIBUCION DE PETROLEO', text: 'IMPUESTO DISTRIBUCION DE PETROLEO'},
        {value:'COMBUSTIBLES', text: 'COMBUSTIBLES'},
        {value:'COMPRAS', text: 'COMPRAS'},
        {value:'VENTAS', text: 'VENTAS'},
        {value:'SERVICIOS PRESTADOS', text: 'SERVICIOS PRESTADOS'},
        {value:'BANCOS', text: 'BANCOS'},
        {value:'IVA DEBITO', text: 'IVA DEBITO'},
        {value:'IVA CREDITO', text: 'IVA CREDITO'},
        {value:'CLIENTES', text: 'CLIENTES'},
        {value:'PROVEEDORES', text: 'PROVEEDORES'},
        {value:'IMPORTACIONES', text: 'IMPORTACIONES'},
        {value:'ATENCION A CLIENTES Y EMPLEADOS', text: 'ATENCION A CLIENTES Y EMPLEADOS'},
        {value:'LUBRICANTES Y REPUESTOS DE VEHICULOS', text: 'LUBRICANTES Y REPUESTOS DE VEHICULOS'},
        {value:'PAPELERÍA Y UTILES DE OFICINA', text: 'PAPELERÍA Y UTILES DE OFICINA'},
        {value:'AGUA PURA', text: 'AGUA PURA'},
        {value:'ALIMENTOS Y BEBIDAS', text: 'ALIMENTOS Y BEBIDAS'},
        {value:'LUBRICANTES Y REPUESTOS DE MAQUINARIA', text: 'LUBRICANTES Y REPUESTOS DE MAQUINARIA'},
        {value:'MEDICAMENTOS', text: 'MEDICAMENTOS'},
        {value:'MATERIALES DE LIMPIEZA', text: 'MATERIALES DE LIMPIEZA'},
        {value:'OTRAS COMPRAS', text: 'OTRAS COMPRAS'},
        {value:'SUSCRIPCIONES Y SEGUROS', text: 'SUSCRIPCIONES Y SEGUROS'},
        {value:'TELÉFONO, INTERNET Y AGUA', text: 'TELÉFONO, INTERNET Y AGUA'},
        {value:'ENERGÍA ELÉCTRICA', text: 'ENERGÍA ELÉCTRICA'},
        {value:'REPARACIÓN Y PARQUEO DE VEHÍCULOS', text: 'REPARACIÓN Y PARQUEO DE VEHÍCULOS'},
        {value:'ARRENDAMIENTOS', text: 'ARRENDAMIENTOS'},
        {value:'ARRENDAMIENTOS Y SERVICIOS DE ASESORÍA', text: 'ARRENDAMIENTOS Y SERVICIOS DE ASESORÍA'},
        {value:'MANTENIMIENTO DE MOBILIARIO Y EQUIPO', text: 'MANTENIMIENTO DE MOBILIARIO Y EQUIPO'},
        {value:'OTROS SERVICIOS', text: 'OTROS SERVICIOS'},
        {value:'SERVICIOS DE SEGURIDAD', text: 'SERVICIOS DE SEGURIDAD'},
        {value:'SERVICIOS DE TRANSPORTE Y ENCOMIENDA', text: 'SERVICIOS DE TRANSPORTE Y ENCOMIENDA'},
        {value:'ASESORÍAS', text: 'ASESORÍAS'},
        {value:'SERVICIOS CONTABLES', text: 'SERVICIOS CONTABLES'},
        {value:'MAQUINARIA Y HERRAMIENTA', text: 'MAQUINARIA Y HERRAMIENTA'},
        {value:'MOBILIARIO Y EQUIPO', text: 'MOBILIARIO Y EQUIPO'},
        {value:'VEHICULOS', text: 'VEHICULOS'},
        {value:'INMUEBLES', text: 'INMUEBLES'},
        {value:'EQUIPO DE COMPUTO', text: 'EQUIPO DE COMPUTO'},
        {value:'GASTOS DE INSTALACION', text: 'GASTOS DE INSTALACION'},
        {value:'OTROS ACTIVOS', text: 'OTROS ACTIVOS'},
        {value:'SUELDOS, SALARIOS Y BONIFICACIONES', text: 'SUELDOS, SALARIOS Y BONIFICACIONES'},
        {value:'CUOTA PATRONAL IGSS, IRTRA E INTECAP', text: 'CUOTA PATRONAL IGSS, IRTRA E INTECAP'},
        {value:'AGUINALDO Y BONO 14', text: 'AGUINALDO Y BONO 14'},
        {value:'VACACIONES PAGADAS', text: 'VACACIONES PAGADAS'},
        {value:'OTROS GASTOS NO AFECTOS', text: 'OTROS GASTOS NO AFECTOS'},
        {value:'COMPRAS EXCENTAS', text: 'COMPRAS EXCENTAS'},
        {value:'SERVICIOS PROFESIONALES', text: 'SERVICIOS PROFESIONALES'},
        {value:'ABARROTES', text: 'ABARROTES'},

        {value:'VESTUARIO Y ACCESORIOS', text: 'VESTUARIO Y ACCESORIOS'},
        {value:'FERRETERÍA', text: 'FERRETERÍA'},
        {value:'MEDICAMENTOS Y GASTOS MÉDICOS', text: 'MEDICAMENTOS Y GASTOS MÉDICOS'},
        {value:'ABARROTES, UTENCILIOS DE LIMPIEZA', text: 'ABARROTES, UTENCILIOS DE LIMPIEZA'},
        {value:'MANTENIMIENTO DE ACTIVOS MOBILES E INMOBILIARIOS', text: 'MANTENIMIENTO DE ACTIVOS MOBILES E INMOBILIARIOS'},


        {value:'TRANSPORTE Y ENCOMIENDAS', text: 'TRANSPORTE Y ENCOMMIENDAS'},
        {value:'SERVICIOS MÉDICO', text: 'SERVICIOS MÉDICO'},
        {value:'SERVICIOS TÉCNICOS', text: 'SERVICIOS TÉCNICOS'},
        {value:'PUBLICIDAD Y PROPAGANDA', text: 'PUBLICIDAD Y PROPAGANDA'},
      ],
      edit: false,
      rowsPerPage: 100,
      url: 'account_config/',
      url_accounts: 'accounting_account/',
      sending: false,
      confSaved: false,
      sending_request: false,
      filters:{
        limit: 75,
        status: true,
      },
      showDialog: false,
      showDialogListConfig: false,
    };
  },
  methods: {
    go_to(url) {
      this.$router.push(url);
    },
    change_to_edit_detail(item, index) {
      item.edit = !item.edit;
      item.status = 1;
      this.$set(this.list_data.mdData, index, item);
    },
    cancel(item) {
      item.edit = false;
      this.list(1, this.rowsPerPage);
    },
    set_account(obj) {
      this.list_data.mdData.map(dato =>{
        if(dato.account_name && dato.status===1){
          dato.account_name = obj.name;
          if (obj.name_account_bank) {
            dato.account_name = obj.name +' ('+ obj.name_account_bank+' )';
          }
          dato.nomenclature = obj.nomenclature;
          dato.account = obj.id;
        }
      });
    },
    get_list_accounts(searchTerm) {
      const payload = {};
      if (searchTerm) {
        payload.toke = searchTerm
        payload.filter = "True"
        payload.accountSearch = "1"
      }
      filter: "True"
      const self = this;
      this.sendRequest(payload, this.url_accounts).then((data) => {
        self.list_accounts = data.data;
      }).catch((error) => {
        console.log(error);
        this.$swal.fire('Error!', 'No fue posible obtener los registros.', 'error');
      });
      return null;
    },
    save_storage(key, data) {
      localStorage.setItem([key], JSON.stringify(data));
    },
    remove_storage(key) {
      localStorage.removeItem([key]);
    },
    get_data_storage(name) {
      return JSON.parse(localStorage.getItem(name));
    },
    get_data_raw(name) {
      return localStorage.getItem(name);
    },
    reset_filters: function () {
      this.filters = {
        limit: 75,
        status: true,
      }
      this.list()
    },
    list_resumen() {
      this.showDialogListConfig=true
      let payload = {};
      const self = this;       
      payload.office = this.office
      payload.resumen = "True"

      if (this.sending_request) return false
        this.sending_request = true
        this.sendRequest_with_url(this.url, payload).then((data) => {
        self.rowsPerPage = 10
        self.list_data_bases = data.data
        this.sending_request = false
      }).catch((error) => {
        this.sending_request = false
        this.handlerError(error)
      });
      return null;
    },
    list() {
      let payload = {};
      const self = this;
      payload.company = this.office
      
      if (Object.keys(this.filters).length > 0){
        Object.assign(payload, this.filters)
      }
      if (this.filters.status) {
        payload.status = "True"
      }
      if (!this.filters.status && this.filters.status != undefined) {
        payload.status = "False"
      }

      if (this.sending_request) return false
        this.sending_request = true
        this.sendRequest_with_url(this.url, payload).then((data) => {
        self.rowsPerPage = 10
        self.list_data = {
          mdData: data.data
        }
        this.sending_request = false
      }).catch((error) => {
        this.sending_request = false
        this.handlerError(error)
      });
      return null;
    },
    async retrieveDuplicate (id_config) {
      const self = this
      const payload = {
        id: id_config,
        office: this.office,
        duplicate: "True"
      }
      await this.retrieveRequest('company/', payload).then(function (data) {        
        self.list_data = {
          mdData: data.data
        }
        self.showDialogListConfig=false
        self.$swal.fire('Creado!',"La configuración ha sido creada", 'success')
      }).catch(function (error) {
        console.log(error)
        self.handlerError(error)
      })
    },
    confirmRow(item, index) {
      if (!item.account_name) {
        this.$swal.fire('Error!', 'La cuenta es requerida.', 'error')
        return false
      }
      const payload = item
      const self = this
      this.sending = true
      if (item.id) {
        this.putRequest(payload).then(function (data) {
          self.$set(self.list_data.mdData, index, data.data)
          self.message = data.data.description
          self.sending = false
          self.confSaved = true
        }).catch(function (error) {
          self.sending = false
          if (error.response){
            if (typeof (error.response.data) === 'object') {
              const result = error.response.data
              Object.entries(result).forEach(
                ([key, value]) => self.$swal.fire('Error!', key + ': ' + value, 'error')
              )
              return false
            }
            self.$swal.fire('Error!', error.response.data, 'error')
            return false
          }
          self.$swal.fire('Error!', error, 'error')
        })
        return
      }
    },
    delete_document(item, index){
      if (item.id){
        const self = this
        Swal.fire({
            title: "¿Esta seguro?",
            text: "El registro será deshabilitado y no se podrá recuperar.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            showLoaderOnConfirm: true,
            preConfirm: function (email) {
                const payload = item
                payload.status = "False"
               return self.putRequest(payload).then(function (data){
                  Swal.fire(
                    'Anulado!',
                    'El registro ha sido deshabilitado.',
                    'success'
                  )
                  self.sending = false
                  self.$set(self.list_data.mdData, index, data.data)
                }).catch(function (error) {
                  self.sending = false
                  if (typeof (error.response.data) === 'object') {
                    const result = error.response.data
                    Object.entries(result).forEach(
                      ([key, value]) => self.$swal.fire('Error!', key + ': ' + value, 'error')
                    )
                    return false
                  }
                  self.$swal.fire('Error!', error.response.data, 'error')
                })
            },
          }).then((result) => {
            if (result.isConfirmed) {
            }else {
              item.edit = false
              Swal.fire(
                'Cancelado',
                'El registro esta seguro.',
                'error'
              )

            }
        });
      }
    },
    async sendRequest(payload, url) {
      const result = await this.$http.get(url,{params:  payload});
      return result;
    },
    async sendRequest_with_url(url, payload) {
      // const result = await this.$http.get(this.url, payload);
      const result = await this.$http.get(url,{params:  payload});
      return result;
    },
    async putRequest(payload) {
      const result = await this.$http.put(`${this.url}${payload.id}/`, payload)
      return result
    },
    async retrieveRequest (url, _payload) {
      const result = await this.$http.get(url + _payload.id + '/', { params: _payload })
      return result
    },
    handlerError: function (error) {
      if (typeof (error.response.data) === 'object') {
        const result = error.response.data
        Object.entries(result).forEach(
          ([key, value]) => this.$swal.fire('Error!', key + ': ' + value, 'error')
        )
        return false
      }
      this.$swal.fire('Error!', error.response.data, 'error')
    },
  },
  filters: {
    status_to_text(value){
      if (value) return 'Sí';
      return 'No';
    },
  },
};

</script>
<style lang="scss" scoped>
  .md-table + .md-table {
    margin-top: 16px
  }
  .avatar img {
    max-width: 30px;
  }
</style>
<style lang="css" scoped>
  .on-over {
    cursor: pointer;
  }
  .md-card-content{
    overflow-y: auto;
  }
  .fa-refresh {
      -webkit-animation: infinite-spinning 5s normal linear infinite;
      animation: infinite-spinning 5s normal linear infinite;
  }
  .null{
    text-decoration: line-through;
  }
  .fade-enter-active {
    transition: all .5s ease;
  }
  .fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .fade-enter, .fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }
  .autocomplete{
    width: 350px;
  }
  .customWidthClass2{
    width: 40%;
  }

  @keyframes infinite-spinning {
    0% {
       -webkit-transform: rotate3d(0, 0, 1, 0deg);
       transform: rotate3d(0, 0, 1, 0deg);
     }
     25% {
       -webkit-transform: rotate3d(0, 0, 1, 90deg);
       transform: rotate3d(0, 0, 1, 90deg);
     }
     50% {
       -webkit-transform: rotate3d(0, 0, 1, 180deg);
       transform: rotate3d(0, 0, 1, 180deg);
     }
     75% {
       -webkit-transform: rotate3d(0, 0, 1, 270deg);
       transform: rotate3d(0, 0, 1, 270deg);
     }
     100% {
       -webkit-transform: rotate3d(0, 0, 1, 360deg);
       transform: rotate3d(0, 0, 1, 360deg);
     }
  }
  .bounce-enter-active {
    animation: bounce-in .5s;
  }
  .bounce-leave-active {
    animation: bounce-in .5s reverse;
  }
  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  .error-text {
    color: #958c8c;
  }
</style>
